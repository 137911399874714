<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="flex flex-wrap p-fluid mt-4">
    <!-- <div class="col-12 md:col-6">
    <app-global-value-list-suggestions-selector
      label="Title"
      [placeholder]="'Enter Title'"
      [control]="formGroup?.controls?.subject"
      [viewMode]="fieldViewMode"
      [listName]="'Policy Sections'"
      >
    </app-global-value-list-suggestions-selector>
  </div> -->
    <div class="col-12 md:col-12">
      <app-basic-input
        label="Title"
        [placeholder]="'Enter Title'"
        [control]="formGroup?.controls?.subject"
        [viewMode]="'view'"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'Parent'"
        [placeholder]="'Search Policy Body Sections'"
        [control]="formGroup?.controls?.parent"
        [viewMode]="'view'"
        [multi]="false"
        [excludeCodes]="data?.code ? [data?.code] : []"
        [targetTypes]="['POLICY_BODY_SECTION']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Type'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="policySectionTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.type"
        [badgeView]="true"
        [viewMode]="'view'"
      ></app-drop-down-input>
    </div>
    @if (fieldViewMode != 'create') {
      <div class="col-12 md:col-12">
        <app-target-code-selector
          [label]="'Policy Body'"
          [placeholder]="'Policy Body'"
          [control]="formGroup?.controls?.policyBody"
          [viewMode]="'view'"
          [readOnly]="false"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
        >
        </app-target-code-selector>
      </div>
    }
    <!-- <div class="col-12 md:col-12">
  <app-text-editor [name]="'body'"
    label="Section Body"
    [placeholder]="'Enter Text...'"
    [control]="formGroup?.controls?.body"
    [viewMode]="fieldViewMode"
  [advanced]="true"></app-text-editor>
</div> -->
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'body'"
        label="Section Body"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.body"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
