import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, PolicyBodySection, PolicyBodySectionDto, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services';
@Component({
  selector: 'app-policy-body-section-item-form',
  templateUrl: './policy-body-section-item-form.component.html',
  styleUrls: ['./policy-body-section-item-form.component.scss'],
})
export class PolicyBodySectionItemFormComponent extends BaseForm<PolicyBodySectionDto> implements OnInit {
  policySectionTypes = getEnumOptions(PolicyBodySection.TypeEnum);

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'POLICY_BODY_SECTION');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      subject: new FormControl(null, Validators.required),
      body: new FormControl(null),
      parent: new FormControl(null),
      policyBody: new FormControl(null),
      type: new FormControl(null, Validators.required),
      // frameworkRef: new FormControl(null, Validators.required),
      // citationType: new FormControl(null),
      // description: new FormControl(null),
      // notes: new FormControl(null),
      // authorityDocument: new FormControl(null),
      // parent: new FormControl(null)
    });
  }
}
